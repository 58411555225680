(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"));
	else if(typeof define === 'function' && define.amd)
		define("ReactSimpleKeyboard", ["react"], factory);
	else if(typeof exports === 'object')
		exports["ReactSimpleKeyboard"] = factory(require("react"));
	else
		root["ReactSimpleKeyboard"] = factory(root["React"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE__0__) {
return 